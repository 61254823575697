<template>
  <v-navigation-drawer
    :value="value"
    @input="$emit('input', $event)"
    temporary
    absolute
  >
    <v-card class="mx-auto" width="300">
      <v-list>
        <v-list-item>
          <v-list-item-icon>
            <v-icon>mdi-home</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Home</v-list-item-title>
        </v-list-item>

       <v-list-item
          to="/dashboard/mistakes"
          :exact="true"
          @click.stop="$emit('input', false)"
        >
          <v-list-item-icon>
            <v-icon>mdi-alert-circle</v-icon>
          </v-list-item-icon>
          <v-list-item-title> Erros </v-list-item-title>
      </v-list-item>

        <v-list-group prepend-icon="mdi-bank">
          <template v-slot:activator>
            <v-list-item-title>Financeiro</v-list-item-title>
          </template>

          <v-list-group no-action sub-group>
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>Pessoa Física</v-list-item-title>
              </v-list-item-content>
              <v-icon>people</v-icon>
            </template>

            <v-list-item
              dense
              v-for="(item, i) in privatePerson"
              :key="i"
              :to="item.url"
              :exact="item.exact"
              @click.stop="$emit('input', false)"
            >
              <v-list-item-icon>
                <v-icon v-text="item.icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-title v-text="item.title"></v-list-item-title>
            </v-list-item>
          </v-list-group>

          <v-list-group no-action sub-group>
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>Pessoa Jurídica</v-list-item-title>
              </v-list-item-content>
              <v-icon>apartment</v-icon>
            </template>

            <v-list-item
              dense
              v-for="(item, i) in legalEntities"
              :key="i"
              :to="item.url"
              :exact="item.exact"
              @click.stop="$emit('input', false)"
            >
              <v-list-item-icon>
                <v-icon v-text="item.icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-title v-text="item.title"></v-list-item-title>
            </v-list-item>
          </v-list-group>

          <v-list-group no-action sub-group>
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>Buzz Financeiro</v-list-item-title>
              </v-list-item-content>
              <v-icon>mdi-account-cash</v-icon>
            </template>

            <v-list-item
              dense
              v-for="(item, i) in buzz"
              :key="i"
              :to="item.url"
              :exact="item.exact"
              @click.stop="$emit('input', false)"
            >
              <v-list-item-icon>
                <v-icon v-text="item.icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-title v-text="item.title"></v-list-item-title>
            </v-list-item>
          </v-list-group>
        </v-list-group>
      </v-list>
    </v-card>
  </v-navigation-drawer>
</template>

<script>

export default {
  name: 'AppMenu',
  props: {
    value: Boolean
  },

  data: () => ({
    privatePerson: [
      {
        title: 'Actions', icon: 'mdi-account-cog-outline', url: '/dashboard/private-person', exact: true
      },
      {
        title: 'Files', icon: 'drive_folder_upload', url: '/dashboard/private-person-reports', exact: true
      },
      {
        title: 'Export', icon: 'file_download', url: '/dashboard/export-private-person', exact: true
      }
    ],
    legalEntities: [
      {
        title: 'Actions', icon: 'mdi-account-cog-outline', url: '/dashboard/legal-entities', exact: true
      },
      {
        title: 'Files', icon: 'drive_folder_upload', url: '/dashboard/legal-entities-reports', exact: true
      },
      {
        title: 'Export', icon: 'file_download', url: '/dashboard/export-legal-entities', exact: true
      }
    ],
    buzz: [
      {
        title: 'Actions', icon: 'mdi-account-cog-outline', url: '/dashboard/buzz', exact: true
      },
      {
        title: 'Files', icon: 'drive_folder_upload', url: '/dashboard/buzz-reports', exact: true
      }
    ],
    user: {}
  }),

  async created() {
    this.user = JSON.parse(localStorage.getItem('user'));
  }
};
</script>

<style>
</style>
