<template>
  <v-app-bar
    app
    fixed
    color="primary"
    prepend-icon="person"
    @click.stop="$emit('hide', !show)"
  >
    <v-app-bar-nav-icon></v-app-bar-nav-icon>
    <v-toolbar-title>{{ title || 'Dashboard' }}</v-toolbar-title>
    <v-spacer></v-spacer>
    <span>Exit</span>
    <v-btn icon @click.stop="logout">
      <v-icon>exit_to_app</v-icon>
    </v-btn>
  </v-app-bar>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  name: 'AppToobar',
  props: {
    show: Boolean
  },

  model: {
    prop: 'show',
    event: 'hide',
  },

  computed: {
    ...mapState(['title']),
  },

  methods: {
    ...mapActions(['signOut', 'getUser']),

    async logout() {
      await this.signOut(this.getUser);
      this.$router.push('/login');
    }
  }
};
</script>
