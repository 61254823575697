<template>
  <div>
    <app-toobar v-model="drawer" />
    <app-menu v-model="drawer" />

    <v-container grid-list-md>
      <v-layout>
        <v-flex xs12>
          <slot />
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import AppToobar from './AppToobar.vue';
import AppMenu from './AppMenu.vue';

export default {
  name: 'AppLayout',
  components: { AppMenu, AppToobar },
  data: () => ({
    drawer: false,
  })
};
</script>

<style>
</style>
