<template>
  <app-layout>
    <router-view />
  </app-layout>
</template>

<script>
import AppLayout from '../components/AppLayout.vue';

export default {
  name: 'Dashboard',
  components: {
    AppLayout,
  },
};
</script>

<style>
html {
  overflow-y: auto;
}
</style>
